var _sentryCollisionFreeGlobalObject = typeof window != "undefined" ? window : typeof global != "undefined" ? global : typeof self != "undefined" ? self : {};
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = "/monitoring";
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"be6ec006a3df4b04cf801d1799b2f55ba92f1f35"};
_sentryCollisionFreeGlobalObject["__sentryBasePath"] = undefined;
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

import * as Sentry from "@sentry/nextjs";

import { publicEnv } from "@chapter/next-env";

const env = publicEnv();
const environment = env.NEXT_PUBLIC_APP_ENV ?? "development";
Sentry.init({
    dsn: "https://5401ef1eee8049a5f131fa5f4f962341@o1009884.ingest.sentry.io/4505908502724608",
    // TODO: adjust this value in production
    tracesSampleRate: 1,
    enabled: environment !== "development",
    release: env.NEXT_PUBLIC_APP_VERSION,
    debug: false,
    environment,
    ignoreErrors: [
        "Non-Error exception captured", // allow objects that are not instances of Error to be captured
        "Can't find variable: gmo", // https://issues.chromium.org/issues/396043331
    ],
    integrations: [
        new Sentry.Replay({
            maskAllText: false,
            maskAllInputs: false,
            blockAllMedia: false,
        }),
    ],
    replaysSessionSampleRate: 0, // Don't screen record outside of errors
    replaysOnErrorSampleRate: 1,
});
